import React from 'react';
import { graphql } from 'gatsby';

export default ({ node }) => {
  return (
    <section className="section-body" id="benefits-body">
      <div className="content">
        <div className="content--main">
          {node.eyebrow && (
            <p className="eyebrow text-align-center">{node.eyebrow}</p>
          )}
          <span
            className="text-align-center"
            dangerouslySetInnerHTML={{ __html: node.text }}
          />
          <div
            id="benefits-grid"
            className="grid"
            data-masonry='{ "columnWidth": ".grid-sizer", "gutter": ".gutter-sizer", "itemSelector": ".grid-item", "percentPosition": true, "stagger": 30}'
          >
            <div className="grid-sizer" />
            <div className="gutter-sizer" />
            {node.benefits.map((benefit, i) => {
              let img = benefit.image;

              return (
                <div key={i} className="grid-item">
                  {img && (
                    <img
                      srcSet={img.localFile.childCloudinaryAsset.fluid.srcSet}
                      sizes={img.localFile.childCloudinaryAsset.fluid.sizes}
                      src={img.localFile.childCloudinaryAsset.fluid.src}
                      alt={benefit.image.alt_text}
                    />
                  )}
                  <span dangerouslySetInnerHTML={{ __html: benefit.benefit }} />
                </div>
              );
            })}
          </div>
          {node.text_below_benefits && (
            <span
              className="text-align-center"
              dangerouslySetInnerHTML={{ __html: node.text_below_benefits }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export const benefitsQuery = graphql`
  fragment Benefits on wordpress__PAGE {
    title
    slug
    acf {
      content_page {
        __typename
        ... on WordPressAcf_our_benefits {
          eyebrow
          text
          text_below_benefits
          benefits {
            benefit
            image {
              localFile {
                childCloudinaryAsset {
                  fluid {
                    ...CloudinaryAssetFluid
                  }
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`;
