import React   from 'react';
import parse, { domToReact } from 'html-react-parser';
import {useRef, useContext, useEffect} from 'react';
import { HeaderContext } from '../context/HeaderContext';
import { graphql } from "gatsby";
import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Blocks(props) {

  const ctxRef = useRef(useContext(HeaderContext));

  useEffect(() => {
    var context = ctxRef;

    context.current.resetLogoLight();
    let slideSettings = {};

    if ( $(".slick-slider") ){
      slideSettings = {
        adaptiveHeight: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
        dots: false,
        draggable: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              dots: true,
            }
          }
        ],
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false
      };

      $(".slick-slider").slick(slideSettings);
      
      $(".slick-slider").on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('a[data-slide]').removeClass('active');
        $(`a[data-slide='${currentSlide}']`).addClass('active');
      });

      $('a[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('a[data-slide]').removeClass('active');
        $(this).addClass('active');
        $('.slick-slider').slick('slickGoTo', slideno);
      });
    }

    return () => {
      var context = ctxRef;

      context.current.resetLogoDark();

      if ( $(".slick-slider") ){
        slideSettings = {}  
        $(".slick-slider").slick('unslick'); 
      } 
    }
  }, []);

  const node = props.node;
  const html = node.content;

  const newOptions = {
    replace: ({ name, attribs, children }) => {
      if (!attribs || !name) return;
      
      if (attribs.class === "content" || attribs.class === "content--main")
          return <>{domToReact(children, newOptions)}</>
      
      if(name === 'img'){
        var src = attribs.src;
        var filename = src.substring(src.lastIndexOf("/") + 1, src.lastIndexOf("."));
        attribs.src = "https://res.cloudinary.com/judah-firewood/image/upload/fwm-images/" + filename;
        attribs.width = "";
        attribs.height = "";
        attribs.sizes = "(max-width: 960px) 100vw, 960px";
        attribs.srcset = 'https://res.cloudinary.com/judah-firewood/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_960/fwm-images/' + filename + ' 960w, https://res.cloudinary.com/judah-firewood/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_320/fwm-images/' + filename + '_mobile 320w, https://res.cloudinary.com/judah-firewood/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/fwm-images/' + filename + '_mobile 480w, https://res.cloudinary.com/judah-firewood/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_640/fwm-images/' + filename + '_mobile 640w';
        
        return (
          
            <picture>
              <source srcSet={attribs.srcset} sizes={attribs.sizes} />
              <img sizes={attribs.sizes} srcSet={attribs.srcset} src={attribs.src} alt="true" loading="lazy" />
            </picture>
          
        )
      }
      
    }
  }
  const options = {
    replace: ({ name, attribs, children }) => {
      
      if (!attribs || !name) return;

      if(name === 'html' || name === 'head' || name === 'body')
        return <>{domToReact(children, options)}</>

      if (attribs.class === "section-body graph-scroll") {     

        return (
          <section className="section-body graph-scroll" style={{backgroundColor: "rgb(0, 0, 0)"}}>
            <div className="content">
              <div className="content--main">
                
                
                  <>{domToReact(children, newOptions)}</>
               

              </div>
            </div>
          </section>
        )
        
      }
    }
  }

  return (
    parse( html, options )    
  );
};

export default Blocks;

export const bodyQuery = graphql`
  fragment Blocks on wordpress__PAGE {
    content
  }
`;
