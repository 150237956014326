import React from "react";
import Body from "../components/Body";
import Hero from "../components/Hero";
import Info from "../components/Info";
import Grid from "../components/Grid";
import Interstitial from "../components/Interstitial";
import Benefits from "../components/Benefits";
import Blocks from "../components/Blocks";

const FlexibleContent = ({ currentPage, ...props }) => {
  return (
    currentPage.acf.content_page !== null ?
      currentPage.acf.content_page.map((layout, i) => {
        if (layout.__typename === `WordPressAcf_body`) {
          return <Body key={i} index={i} node={layout} />;
        }

        if (
          layout.__typename === `WordPressAcf_hero` ||
          layout.__typename === `WordPressAcf_partners`
        ) {
          return <Hero key={i} node={layout} />;
        }

        if (
          layout.__typename === `WordPressAcf_info_with_image` ||
          layout.__typename === `WordPressAcf_info_with_thumbs` ||
          layout.__typename === `WordPressAcf_info_with_thumbs_images` ||
          //layout.__typename === `WordPressAcf_testimonials` ||
          layout.__typename === `WordPressAcf_carousel`
        ) {
          return <Info key={i} node={layout} />;
        }

        if (layout.__typename === `WordPressAcf_six_eight_up_grid`) {
          return <Grid key={i} gridId={i} node={layout} />;
        }

        if (layout.__typename === `WordPressAcf_interstitial`) {
          return <Interstitial key={i} node={layout} />;
        }

        if (layout.__typename === `WordPressAcf_our_benefits`) {
          return <Benefits key={i} node={layout} />;
        }

        return null;
      })
    : 
    <Blocks node={currentPage} />
  );
};

export default FlexibleContent;
