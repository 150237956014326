import React from "react"
import { graphql } from 'gatsby'

export default ({ gridId, node }) => {
	let gridNumber = (node.item.length <= 6) ? "6" : "8"

	return (
		<section id={"section-" + gridId} className={"section-grid-" + gridNumber}>
		{
			node.item.map( (thisItem, i) => {
				return(
					<div key={i} className="item">
						<div className="item--content">
						{thisItem.image !== false &&
							<img srcSet={thisItem.image.localFile.childCloudinaryAsset.fluid.srcSet} src={thisItem.image.localFile.childCloudinaryAsset.fluid.src} sizes={thisItem.image.localFile.childCloudinaryAsset.fluid.sizes} alt={thisItem.image.alt_text} height="120" />
						}
							<h5 className="item--title" dangerouslySetInnerHTML={{__html: thisItem.title}} />
							<span dangerouslySetInnerHTML={{__html: thisItem.text}} />
						</div>
		      </div>
				)
			})
		}
  	</section>
)}

export const gridQuery = graphql`
  	fragment Grid on wordpress__PAGE {
  		acf{
  			content_page{
          __typename
					... on WordPressAcf_six_eight_up_grid{
            item{
              image {
                localFile{
					childCloudinaryAsset{
                    fluid(maxWidth: 175){
                      ...CloudinaryAssetFluid
                    }
                  }
                }
                alt_text
              }
              title
              text
            }
          }
        }
      }
    }
`
